// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import "firebase/auth"
import { auth } from "../components/firebase";
import createAxiosInstance from "../utils/request";



const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
          });
      
          return () => unsubscribe();
    }
    
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () =>  useContext(AuthContext);

export const useAxiosInstance = () => {
  const { currentUser } = useAuth();
  const instance = createAxiosInstance(currentUser);
  return instance;
}
