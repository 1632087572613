import axios from "axios";
import { getToday } from "./date";


const NOT_LOGIN_COUNT_LIMIT = 'User not logged and count limit'

const createAxiosInstance = (currentUser) => {
    const instance = axios.create({
        baseURL:process.env.API_HOST
    });
    
    instance.interceptors.request.use(
        (config) =>{
            //请求前校验
            console.log('config',config);
            //校验次数
            let usedCounts = JSON.parse(localStorage.getItem('usedCounts'));
            if(!!usedCounts){
                //如果未登录，判断次数
                if(!!!currentUser){
                    if(usedCounts.totalCount>=20&&usedCounts.lastDate===getToday()){
                        return Promise.reject(new Error(NOT_LOGIN_COUNT_LIMIT));
                    }
                }
                
            }
    
            return config;
        },
        error => {
          // 处理请求错误
          return Promise.reject(error);
        }
    );
    
    // 响应拦截器
    instance.interceptors.response.use(
        response => {
          // 处理响应数据
          //请求成功，次数加1
          if(response.data.success){
            //
            let usedCounts = JSON.parse(localStorage.getItem('usedCounts'));
            //不存在则新建
            if(!!!usedCounts){
                usedCounts = {
                    lastDate:getToday(),
                    totalCount:1
                }
            }else{
                usedCounts.totalCount++;
            }
            localStorage.setItem('usedCounts',JSON.stringify(usedCounts));
          }
    
          return response.data;
        },
        error => {
          if (error.message === NOT_LOGIN_COUNT_LIMIT) {
            // 如果用户未登录，重定向到登录页面
            window.location.href=`/login/?redirect_url=${encodeURIComponent(window.location.href)}`
          }
          // 处理错误响应
          return Promise.reject(error);
        }
      );

    return instance;
}
   
  export default createAxiosInstance;