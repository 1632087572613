

function getToday(){
    let today = new Date();
    let year = today.getFullYear(); // 获取当前年份
    let month = today.getMonth() + 1; // 获取当前月份（月份从0开始，所以需要加1）
    let day = today.getDate(); // 获取当前日期
    let formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

export {getToday}