import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { AuthProvider } from "./src/context/AuthContext";
import "./src/styles/global.css";


export const wrapRootElement = ({ element, props }) => {
    return (
        <AuthProvider {...props}>
            <GoogleReCaptchaProvider useRecaptchaNet={true} reCaptchaKey="6LfuqoApAAAAACCirsz8ZVjQNmPH0__arxU_PU_P">
            {element}
            </GoogleReCaptchaProvider>
        </AuthProvider>
    )
}