// src/firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBT95awpuasiJ5KxglmxPaDOLy_Sn0xshY",
    authDomain: "adsapi-408207.firebaseapp.com",
    projectId: "adsapi-408207",
    storageBucket: "adsapi-408207.appspot.com",
    messagingSenderId: "352686441137",
    appId: "1:352686441137:web:d2baecb0bb741637c11890",
    measurementId: "G-P3RRXY49RJ"
  };


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}


export const auth = firebase.auth();
export default firebase;
